import axios from 'axios';

const url = process.env.VUE_APP_ROOT_API ; 

export default {
  
  getRewardCategoryList(){ 
    return axios.get(url + '/opay-api/reward/category/all').then(response =>response.data.payload)
    .catch((error) => { return error.response }); 
  }, 

  createRewardCategory(params){ 
    return axios.post(url + '/opay-api/reward/category/save',params).then(response =>response.data)
    .catch((error) => { return error.response }); 
  },

  getRewardCategoryById(id){ 
    return axios.get(url + '/opay-api/reward/category/'+id).then(response =>response.data.payload)
    .catch((error) => { return error.response }); 
  }, 

  updateRewardCategory(id,params){ 
    return axios.post(url + '/opay-api/reward/category/update/'+id, params).then(response =>response.data)
    .catch((error) => { return error.response }); 
  },

  downloadRwdCategoryImg(key){
    return axios.get(url + '/opay-api/reward/resource-download/category/'+key,{responseType: 'arraybuffer'}).then(response =>Buffer.from(response.data, 'binary').toString('base64'))
    .catch((error) => { return error.response }); 
  },

  uploadRwdCategoryImg(id,formData){
    return axios.post(url + '/opay-api/reward/resource-upload/category/' + id, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }      
    }).then(response =>response.data)
    .catch((error) => { return error.response }); 
  },  

  getRewardCatalogList(){ 
    return axios.get(url + '/opay-api/reward/catalog/all-for-admin/0/1000').then(response =>response.data.payload)
    .catch((error) => { return error.response }); 
  }, 

  createRewardCatalog(params){ 
    return axios.post(url + '/opay-api/reward/catalog/save',params).then(response =>response.data)
    .catch((error) => { return error.response }); 
  },

  getRewardCatalogById(id){ 
    return axios.get(url + '/opay-api/reward/catalog/'+id).then(response =>response.data.payload)
    .catch((error) => { return error.response }); 
  }, 

  removeRewardCatalogById(id){ 
    return axios.delete(url + '/opay-api/reward/catalog/'+id).then(response =>response.data.payload)
    .catch((error) => { return error.response }); 
  }, 

  removeRewardCatalogByIdGet(id){ 
    return axios.get(url + '/opay-api/reward/catalog/remove/'+id).then(response =>response.data.payload)
    .catch((error) => { return error.response }); 
  }, 

  downloadRwdCatalogImg(key){
    return axios.get(url + '/opay-api/reward/resource-download/'+key,{responseType: 'arraybuffer'}).then(response =>Buffer.from(response.data, 'binary').toString('base64'))
    .catch((error) => { return error.response }); 
  },

  uploadRwdCatalogImg(id,formData){
    return axios.post(url + '/opay-api/reward/resource-upload/' + id, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }      
    }).then(response =>response.data)
    .catch((error) => { return error.response }); 
  },  


};